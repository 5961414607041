module.exports = {
  AUTH_CODE_EXCHANGE_STARTED: 'Auth_Code_Exchange_Started',
  AUTH_CODE_EXCHANGE_FINISHED: 'Auth_Code_Exchange_Finished',
  AUTH_CODE_EXCHANGE_FAILED: 'Auth_Code_Exchange_Failed',
  AUTH_REDIRECT_FINISHED: 'Auth_Redirect_Finished',
  PAGE_VIEWED: 'Page_Viewed',
  INSTALL_CLICKED: 'Install_Clicked',
  HOW_CLICKED: 'How_Clicked',
  MODAL_OPENED: 'Modal_Opened',
  CHECKOUT_EMAIL_INPUT_CLEARED: 'Checkout_Email_Input_Cleared',
  CHECKOUT_EMAIL_INPUT_CHANGED: 'Checkout_Email_Input_Changed',
  CHECKOUT_CARD_INPUT_STARTED: 'Checkout_Card_Input_Started',
  CHECKOUT_CARD_INPUT_FINISHED: 'Checkout_Card_Input_Finished',
  CHECKOUT_CARD_INPUT_FAILED: 'Checkout_Card_Input_Failed',
  SAVE_CARD_INPUT_STARTED: 'Save_Card_Input_Started',
  SAVE_CARD_INPUT_FINISHED: 'Save_Card_Input_Finished',
  SAVE_CARD_INPUT_FAILED: 'Save_Card_Input_Failed',
  SAVE_CARD_BUTTON_CLICKED: 'Save_Card_Button_Clicked',
  CONFIRM_SUBSCRIPTION_CLICKED: 'Confirm_Subscription_Clicked',
  SEND_LINK_CLICKED: 'Send_Link_Clicked',
  PAYMENT_METHOD_SAVED: 'Payment_Method_Saved',
  PAYMENT_PROCESSING_STARTED: 'Payment_Processing_Started',
  PAYMENT_PROCESSING_FAILED: 'Payment_Processing_Failed',
  PAYMENT_PROCESSING_FINISHED: 'Payment_Processing_Finished',
  BUY_BUTTON_CLICKED: 'Buy_Button_Clicked',
  EMAIL_INPUT_CHANGED: 'Email_Input_Changed',
  VIDEO_INPUT_CHANGED: 'Email_Input_Changed',
  PRICING_PLAN_CLICKED: 'Pricing_Plan_Clicked',
  CALCULATE_PRICE_CLICKED: 'Calculate_Price_Clicked',
  CALCULATE_PRICE_FINISHED: 'Calculate_Price_Finished',
  CALCULATE_PRICE_FAILED: 'Calculate_Price_Failed',
  TRANSCRIBE_VIDEO_CLICKED: 'Transcribe_Video_Clicked',
  FREE_TRANSCRIPT_FAILED: 'Free_Transcript_Failed',
  FREE_TRANSCRIPT_FINISHED: 'Free_Transcript_Finished',
  FREE_TRANSCRIPT_CLICKED: 'Free_Transcript_Clicked',
  MODAL_SHARE_WHATSAPP_CLICKED: 'Modal_Share_Whatsapp_Clicked',
  MODAL_SIGN_UP_NEWSLETTER_CLICKED: 'Modal_Sign_Up_Newsletter_Clicked',
  FOOTER_SIGN_UP_NEWSLETTER_CLICKED: 'Footer_Sign_Up_Newsletter_Clicked',
  BLOG_SIGN_UP_NEWSLETTER_CLICKED: 'Blog_Sign_Up_Newsletter_Clicked',
  BLOG_PAGE_SIGN_UP_NEWSLETTER_CLICKED: 'Blog_Page_Sign_Up_Newsletter_Clicked',
  BLOG_PAGE_SHARING_BUTTON_CLICKED: 'Blog_Page_Sharing_Button_Clicked',
  TRANSCRIBE_VIDEO_STARTED: 'Transcribe_Video_Started',
  TRANSCRIBE_VIDEO_FINISHED: 'Transcribe_Video_Finished',
  TRANSCRIBE_VIDEO_FAILED: 'Transcribe_Video_Failed',
  SUBSCRIPTION_REDIRECT_FINISHED: 'Subscription_Redirect_Finished',
}
