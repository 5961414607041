import { useRef, useCallback } from 'react'
import { COOKIE_CONSENT_LOCAL_STORAGE_KEY } from '../../helpers/constants'

export default function useAmplitude() {
  const amplitude = useRef()

  const load = () => {
    const _amplitude = require('amplitude-js')
    _amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
      serverZone: 'EU',
      optOut:
        window.localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY) ===
        'false',
    })
    amplitude.current = _amplitude.getInstance()
  }

  const logEvent = (name, data) => {
    if (!amplitude.current) {
      load()
    }
    amplitude.current.logEvent(name, data)
  }

  const setUserId = (userId) => {
    if (!amplitude.current) {
      load()
    }
    amplitude.current.setUserId(userId)
  }

  const setUserProperties = (userProperties) => {
    if (!amplitude.current) {
      load()
    }
    amplitude.current.setUserProperties(userProperties)
  }

  const optOut = useCallback(() => {
    if (!amplitude.current) {
      load()
    }
    amplitude.current.setOptOut(true)
  }, [])

  return { logEvent, setUserId, setUserProperties, optOut }
}
